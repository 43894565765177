// Initialize and add the map

const mapElement = document.getElementById('map')
if (mapElement !== null) {
    window.initMap =  function() {

        console.log("map init")

        // The location of Uluru
        const uluru = {lat: 35.4957431, lng: 134.7640533};
        // The map, centered at Uluru
        const map = new google.maps.Map(
            document.getElementById('map'), {zoom: 15, center: uluru});
        // The marker, positioned at Uluru
        var marker = new google.maps.Marker({position: uluru, map: map});
    }
}



