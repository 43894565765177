let form = document.getElementById("inform_form")



if (form !== null) {

    var contactSuccess = function () {
        // handle success
        var sucessBlock = document.getElementById("send_complete")
        sucessBlock.classList.remove("is-hidden")
        form.classList.add("is-hidden")

    }



    var contactError = function (msg) {
        var errorBlock = document.getElementById("send_error")
        var failMsgBlock = document.getElementById("fail_msg")
        failMsgBlock.innerText = msg
        errorBlock.classList.remove("is-hidden")
        form.classList.remove("is-hidden")
    }


    // URLパラメータ文字列を取得する
    var params = (new URL(document.location)).searchParams;
    console.log(params)
    var inform = params.get('inform');
    console.log(inform)
    if (inform === 'recruit') {
        document.getElementById("inform_types").value = 'recruit';
    }

    form.onsubmit = (e) => {

        // stop the regular form submission
        e.preventDefault();

        // collect the form data while iterating over the inputs
        var postJson = {};
        for (var i = 0, ii = form.length; i < ii; ++i) {
            var input = form[i];
            if (input.name) {
                postJson[input.name] = input.value;
            }
        }
        console.log(postJson)


        // contactSuccess();
        contactError("お問い合わせが完了しませんでした。しばらく時間を置いてから再度入力してください")


        // オブジェクト取れたがステータスコード500以外は成功扱いになる
        // axios.post("https://6grx2r1t36.execute-api.ap-northeast-1.amazonaws.com/prod/test_sendmail", postJson, {headers: {"Accept": "application/json"}}).then(function (response) {
        //
        //
        //     console.log(response);
        //     contactSuccess();
        // }).catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     console.log(error.response)
        //     console.log(error.response.data)
        //
        //
        //     contactFail("問い合わせ処理に失敗しました")
        // })
    }
}





